import React, { useState } from 'react';
import Calculator from './components/Calculator/Calculator';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Guide from './components/Guide/Guide';
import CountrySelector from './components/CountrySelector';
import ContactSection from './components/Contact/ContactSection';
import LegalDisclaimer from './components/LegalDisclaimer/LegalDisclaimer';
import PaymentGate from './components/PaymentGate/PaymentGate';
import Landing from './components/Landing/Landing';
import { AuthProvider } from './components/AuthContext';
import AuthCallback from './components/AuthCallback';
import ProtectedRoute from './components/ProtectedRoute';

// Colors from calculator theme
const colors = {
  background: {
    darker: '#141414',  // Darkest background
    card: '#242424',    // Card background
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
  },
  primary: '#A48AFB'    // Purple accent
};

// Default country configuration
const defaultCountry = {
  code: 'NL',
  name: 'Netherlands',
  flag: '🇳🇱',
  config: {
    transferTaxRate: 0.02,
    mortgageYears: [10, 15, 20, 25, 30],
    baseMultiplier: 4.5,
    highIncomeThreshold: 80000,
    highIncomeMultiplier: 5.0,
    employmentTypes: [
      { value: 'permanent', label: 'Permanent Contract', reduction: 0 },
      { value: 'temporary', label: 'Temporary Contract', reduction: 0.1 },
      { value: 'self-employed', label: 'Self Employed', reduction: 0.2 }
    ],
    residencyRules: {
      lessThan3Months: 0.7,
      lessThan6Months: 0.8,
      lessThan12Months: 0.9,
      established: 1.0
    },
    additionalCosts: {
      notaryFees: 2000,
      advisorFees: 2500,
      bankFees: 750
    }
  }
};

const Logo = () => (
  <svg width="48" height="48" viewBox="0 0 32 32" style={{ marginRight: '16px' }}>
    <circle cx="16" cy="16" r="16" fill={colors.background.card}/>
    <path 
      d="M16 6L7 13.5V25H25V13.5L16 6Z" 
      fill={colors.background.darker}
      stroke={colors.primary}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect x="14" y="18" width="4" height="7" fill={colors.primary}/>
    <path 
      d="M12 15H20L16 11L12 15Z" 
      fill={colors.primary}
      stroke={colors.primary}
      strokeWidth="1"
      strokeLinejoin="round"
    />
  </svg>
);

const App = () => {
  const [activeSection, setActiveSection] = useState('calculator');
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const pageStyles = {
    backgroundColor: colors.background.darker,
    minHeight: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    paddingBottom: '32px'
  };

  const headerStyles = {
    textAlign: 'left',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px 16px',
    color: colors.text.primary
  };

  const titleWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
  };

  const titleStyles = {
    fontSize: '42px',
    fontWeight: '600',
    color: colors.primary,
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  };

  const subtitleStyles = {
    color: colors.text.secondary,
    fontSize: '18px',
    margin: '0',
  };

  const navStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '24px'
  };

  const buttonStyles = (isActive) => ({
    padding: '10px 20px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    border: 'none',
    minWidth: '120px',
    backgroundColor: isActive ? colors.background.card : 'transparent',
    color: isActive ? colors.primary : colors.text.secondary,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: colors.background.card,
      color: colors.primary
    }
  });

  const renderContent = () => {
    if (!selectedCountry) {
      return (
        <div style={{
          textAlign: 'center',
          padding: '40px 20px',
          color: colors.text.secondary,
          fontSize: '16px'
        }}>
          Please select a country to view mortgage options
        </div>
      );
    }

    return (
      <>
        <nav style={navStyles}>
          <button
            onClick={() => setActiveSection('calculator')}
            style={buttonStyles(activeSection === 'calculator')}
          >
            Calculator
          </button>
          <button
            onClick={() => setActiveSection('guide')}
            style={buttonStyles(activeSection === 'guide')}
          >
            Housing Guide
          </button>
        </nav>

        {activeSection === 'calculator' ? (
          <Calculator 
            countryConfig={selectedCountry.config}
            countryCode={selectedCountry.code}
          />
        ) : (
          <Guide 
            countryConfig={selectedCountry.config}
            countryCode={selectedCountry.code}
            countryName={selectedCountry.name}
          />
        )}
      </>
    );
  };

  const MainContent = () => {
    return (
      <div style={pageStyles}>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <header style={headerStyles}>
            <div style={titleWrapperStyles}>
              <Logo />
              <h1 style={titleStyles}>Life Relocate</h1>
            </div>
            <p style={subtitleStyles}>
              Relocate your life to many european cities!
            </p>
          </header>
          
          <CountrySelector 
            selectedCountry={selectedCountry} 
            setSelectedCountry={setSelectedCountry} 
          />

          {renderContent()}
          <LegalDisclaimer />
          <ContactSection />
        </div>
      </div>
    )
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/success" element={<PaymentGate />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route 
            path="/home" 
            element={
              <ProtectedRoute>
                <MainContent />
              </ProtectedRoute>
            } 
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;