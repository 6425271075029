// src/components/Guide/styles.js
const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  }
};

export const styles = {
  // Main Container
  container: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: `1px solid ${colors.border.default}`,
    marginBottom: '32px'
  },

  mainTitle: {
    fontSize: '28px',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '24px',
    color: colors.text.primary,
    textAlign: 'center',
    fontWeight: '600'
  },

  // Collapsible Sections
  collapsibleSection: {
    marginBottom: '16px',
    border: `1px solid ${colors.border.default}`,
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: colors.background.input
  },

  collapsibleButton: {
    width: '100%',
    padding: '16px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.2s ease',
    color: colors.text.primary,
    '&:hover': {
      backgroundColor: colors.background.darker
    }
  },

  collapsibleContent: {
    padding: '20px',
    borderTop: `1px solid ${colors.border.default}`
  },

  // Section Headers
  sectionTitle: {
    fontSize: '16px',
    color: colors.text.primary,
    margin: 0,
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  subsection: {
    marginBottom: '24px',
    padding: '20px',
    backgroundColor: colors.background.card,
    borderRadius: '8px',
    border: `1px solid ${colors.border.default}`
  },

  subsectionTitle: {
    fontSize: '15px',
    color: colors.primary,
    marginBottom: '16px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  // Lists and Items
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },

  listItem: {
    padding: '12px 0',
    borderBottom: `1px solid ${colors.border.default}`,
    color: colors.text.secondary,
    '&:last-child': {
      borderBottom: 'none'
    },
    '& strong': {
      color: colors.text.primary,
      fontWeight: '600',
      display: 'block',
      marginBottom: '4px'
    }
  },

  nestedList: {
    listStyle: 'disc',
    paddingLeft: '24px',
    marginTop: '8px',
    marginBottom: '8px',
    color: colors.text.secondary,
    '& li': {
      marginBottom: '4px',
      '&:last-child': {
        marginBottom: 0
      }
    }
  },

  // Timeline Sections
  timelineSection: {
    marginBottom: '24px',
    padding: '20px',
    backgroundColor: colors.background.input,
    borderLeft: `3px solid ${colors.primary}`,
    borderRadius: '0 8px 8px 0'
  },

  timelineTitle: {
    fontSize: '16px',
    color: colors.primary,
    marginBottom: '12px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  timelineDescription: {
    color: colors.text.secondary,
    marginBottom: '12px',
    fontSize: '14px',
    lineHeight: 1.6
  },

  timelineItem: {
    position: 'relative',
    paddingLeft: '24px',
    marginBottom: '16px',
    color: colors.text.secondary,
    fontSize: '14px',
    lineHeight: 1.6,
    '&:last-child': {
      marginBottom: 0
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '8px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: colors.primary
    },
    '& strong': {
      color: colors.text.primary,
      fontWeight: '600',
      marginRight: '16px'
    }
  },

  timelineDuration: {
    display: 'inline-block',
    padding: '4px 12px',
    backgroundColor: colors.background.card,
    borderRadius: '4px',
    fontSize: '13px',
    color: colors.primary,
    fontWeight: '500',
    marginBottom: '8px',
    border: `1px solid ${colors.border.default}`,
    marginRight: '8px'
  },

  // Notes and Additional Info
  note: {
    marginTop: '8px',
    fontSize: '14px',
    color: colors.text.muted,
    fontStyle: 'italic'
  },

  documentList: {
    marginTop: '8px',
    marginBottom: '8px',
    color: colors.text.secondary,
    fontWeight: '500'
  },

  asteriskNote: {
    fontSize: '14px',
    color: colors.text.muted,
    marginTop: '12px',
    fontStyle: 'italic'
  },

  // Residency Selector
  residencySelector: {
    backgroundColor: colors.background.input,
    borderRadius: '12px',
    padding: '24px',
    marginBottom: '24px',
    border: `1px solid ${colors.border.default}`
  },

  selectorHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px'
  },

  selectorTitle: {
    fontSize: '16px',
    color: colors.text.primary,
    margin: 0,
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  buttonGroup: {
    display: 'flex',
    gap: '12px',
    marginTop: '16px'
  },

  selectorButton: {
    flex: 1,
    padding: '12px 20px',
    borderRadius: '8px',
    border: `1px solid ${colors.border.default}`,
    backgroundColor: 'transparent',
    color: colors.text.secondary,
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: "'Inter', sans-serif",
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: colors.background.darker,
      borderColor: colors.border.hover
    }
  },

  selectorButtonActive: {
    backgroundColor: colors.background.card,
    borderColor: colors.primary,
    color: colors.primary
  },

  infoIcon: {
    cursor: 'pointer',
    color: colors.primary,
    transition: 'color 0.2s ease',
    '&:hover': {
      color: colors.text.primary
    }
  },

  infoPopup: {
    backgroundColor: colors.background.card,
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '16px',
    border: `1px solid ${colors.border.default}`
  },

  infoSection: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0
    }
  },

  infoTitle: {
    fontSize: '15px',
    color: colors.primary,
    marginBottom: '12px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  infoList: {
    margin: 0,
    padding: '0 0 0 20px',
    fontSize: '14px',
    color: colors.text.secondary,
    '& li': {
      marginBottom: '8px',
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
};