import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyPayment } from '../../services/api';
import { Mail, AlertCircle } from 'lucide-react';
import { LoadingOverlay } from '../LoadingStates';
import { styles } from './styles';

const PaymentGate = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('verifying');
  const [error, setError] = useState(null);
  const verifyAttempted = useRef(false);

  const verifySession = useCallback(async () => {
    if (verifyAttempted.current) return;
    verifyAttempted.current = true;

    try {
      const sessionId = searchParams.get('session_id');
      
      if (!sessionId) {
        setError('No session ID found');
        setStatus('error');
        return;
      }

      const response = await verifyPayment(sessionId);
      
      if (response.success) {
        setStatus('success');
      } else {
        setError('Payment verification failed');
        setStatus('error');
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      setError(error.message || 'Payment verification failed. Please contact support.');
      setStatus('error');
    }
  }, [searchParams]);

  useEffect(() => {
    verifySession();
  }, [verifySession]);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {status === 'verifying' && (
          <>
            <h1 style={styles.title}>Verifying Payment</h1>
            <LoadingOverlay message="Please wait while we verify your payment..." />
          </>
        )}

        {status === 'success' && (
          <>
            <Mail style={styles.emailIcon} />
            <h1 style={styles.emailTitle}>Check Your Email</h1>
            <p style={styles.emailMessage}>
              We've sent you a magic link to access the calculator.
              Please check your email and click the link to continue.
            </p>
            <p style={styles.emailSubmessage}>
              Can't find the email? Check your spam folder or contact our support team: info@liferelocate.com
            </p>
          </>
        )}

        {status === 'error' && (
          <>
            <AlertCircle style={{ ...styles.emailIcon, color: styles.errorTitle.color }} />
            <h1 style={styles.errorTitle}>Verification Failed</h1>
            <p style={styles.error}>{error}</p>
            <p style={styles.submessage}>
              If this issue persists, please contact our support team for assistance: info@liferelocate.com
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentGate;