import React from 'react';

const keyframes = {
  spin: {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
};

export const LoadingSpinner = () => (
  <div style={{
    display: 'inline-block',
    width: '2rem',
    height: '2rem',
    border: '3px solid #e2e8f0',
    borderTopColor: '#0ea5e9',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  }} />
);

export const LoadingOverlay = ({ message }) => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  }}>
    <LoadingSpinner />
    {message && (
      <p style={{
        marginTop: '1rem',
        color: '#1e293b',
        fontSize: '1rem',
        fontWeight: '500'
      }}>{message}</p>
    )}
  </div>
);

// Add keyframes to document
const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleSheet); 