import React from 'react';
import { styles } from './styles';

const SelectField = ({ label, icon: Icon, options, ...props }) => {
  return (
    <div style={styles.formGroup}>
      <label style={styles.label}>{label}</label>
      <div style={styles.selectWrapper}>
        <div style={styles.icon}>
          <Icon size={18} />
        </div>
        <select style={styles.select} {...props}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectField;