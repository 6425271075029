import React, { useEffect } from 'react';
import { LoadingOverlay } from './LoadingStates';
import { API_BASE_URL } from '../services/api';

const AuthCallback = () => {
    useEffect(() => {
        const handleAuthCallback = async () => {
            try {
                
                // Get the complete hash
                const hashParams = new URLSearchParams(window.location.hash.substring(1));
                const accessToken = hashParams.get('access_token');
                const type = hashParams.get('type');
                
            
                if (!accessToken) {
                    throw new Error('No access token found');
                }

                
                try {
                    const response = await fetch(`${API_BASE_URL}/auth/callback`, {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                            access_token: accessToken,
                            type: type
                        }),
                        credentials: 'include'
                    });

                    
                    const data = await response.json();

                    if (!response.ok) {
                        throw new Error(data.error || 'Failed to authenticate');
                    }

                    // Redirect to home page
                    window.location.replace('/home');

                } catch (error) {
                    console.error("API call error:", error);
                    throw error;
                }
            } catch (error) {
                console.error('Auth callback error:', error);
                window.location.replace('/');
            }
        };

        handleAuthCallback();
    }, []);
  
    return <LoadingOverlay message="Signing you in..." />;
};

export default AuthCallback;