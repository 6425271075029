// src/components/Calculator/Calculator.jsx
import React, { useState, useEffect } from 'react';
import { Euro, User, Clock, Percent, Info } from 'lucide-react';
import { calculateMortgage } from '../../utils/calculations';
import { styles, colors } from './styles';
import MortgageBreakdown from './MortgageBreakdown';
import InputField from './InputField';
import SelectField from './SelectField';
import EmploymentTypeTooltip from './EmploymentTypeTooltip';

const formatCurrency = (amount) => {
  return `€${amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
};

const Calculator = ({ countryConfig, countryCode }) => {
  const [showResidencyInfo, setShowResidencyInfo] = useState(false);
  const [formData, setFormData] = useState({
    annualIncome: '',
    savings: '',
    employmentType: 'permanent',
    monthsInCountry: '',
    customInterestRate: '4.5',
    fixedRatePeriod: '10',
    mortgageDuration: countryConfig.mortgageYears[countryConfig.mortgageYears.length - 1].toString()
  });

  const [results, setResults] = useState(null);

  // Reset form when country changes
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      mortgageDuration: countryConfig.mortgageYears[countryConfig.mortgageYears.length - 1].toString()
    }));
  }, [countryCode, countryConfig]);

  const handleCalculate = () => {
    const calculationResults = calculateMortgage(formData, countryConfig);
    setResults(calculationResults);
  };

  return (
    <div style={styles.container}>
      <div style={styles.calculatorForm}>

        {/* Annual Gross Income */}
        <InputField
          label="Annual Gross Income (€)"
          icon={Euro}
          type="number"
          value={formData.annualIncome}
          onChange={(e) => setFormData({ ...formData, annualIncome: e.target.value })}
          placeholder="60000"
        />

        {/* Available Savings */}
        <InputField
          label="Available Savings (€)"
          icon={Euro}
          type="number"
          value={formData.savings}
          onChange={(e) => setFormData({ ...formData, savings: e.target.value })}
          placeholder="25000"
        />

        {/* Interest Rate */}
        <InputField
          label="Interest Rate (%)"
          icon={Percent}
          type="number"
          step="0.1"
          value={formData.customInterestRate}
          onChange={(e) => setFormData({ ...formData, customInterestRate: e.target.value })}
          placeholder="4.5"
        />

        {/* Fixed Rate Period */}
        <SelectField
          label="Fixed Rate Period"
          icon={Clock}
          value={formData.fixedRatePeriod}
          onChange={(e) => setFormData({ ...formData, fixedRatePeriod: e.target.value })}
          options={[
            { value: "1", label: "1 year" },
            { value: "5", label: "5 years" },
            { value: "10", label: "10 years" },
            { value: "20", label: "20 years" },
            { value: "30", label: "30 years" }
          ]}
        />

        {/* Mortgage Duration */}
        <SelectField
          label="Mortgage Duration"
          icon={Clock}
          value={formData.mortgageDuration}
          onChange={(e) => setFormData({ ...formData, mortgageDuration: e.target.value })}
          options={[
            { value: "10", label: "10 years" },
            { value: "15", label: "15 years" },
            { value: "20", label: "20 years" },
            { value: "25", label: "25 years" },
            { value: "30", label: "30 years" }
          ]}
        />

        {/* Employment Type with Enhanced Tooltip */}
        <EmploymentTypeTooltip
          value={formData.employmentType}
          onChange={(e) => setFormData({ ...formData, employmentType: e.target.value })}
        />

        <div style={styles.formGroup}>
          <div style={styles.labelWrapper}>
            <label style={styles.label}>Months in Netherlands</label>
            <div style={styles.infoIconWrapper}>
              <Info 
                size={18}
                style={styles.infoIcon}
                onMouseEnter={() => setShowResidencyInfo(true)}
                onMouseLeave={() => setShowResidencyInfo(false)}
              />
              {showResidencyInfo && (
                <div style={styles.tooltip}>
                  <h4 style={styles.tooltipHeader}>
                    How Residency Duration Normally Affects Your Mortgage
                  </h4>
                  
                  <div style={styles.tooltipSection}>
                    <div style={styles.tooltipTitle}>Less than 3 months:</div>
                    <p style={styles.tooltipText}>
                      30% reduction in lending capacity. Most banks require longer residency.
                    </p>
                  </div>
                  
                  <div style={styles.tooltipSection}>
                    <div style={styles.tooltipTitle}>3-6 months:</div>
                    <p style={styles.tooltipText}>
                      20% reduction. Banks consider this probation period with higher risk.
                    </p>
                  </div>
                  
                  <div style={styles.tooltipSection}>
                    <div style={styles.tooltipTitle}>6-12 months:</div>
                    <p style={styles.tooltipText}>
                      10% reduction. More banks willing to consider with proven stability.
                    </p>
                  </div>
                  
                  <div style={styles.tooltipSection}>
                    <div style={styles.tooltipTitle}>Over 12 months:</div>
                    <p style={styles.tooltipText}>
                      No reduction. Standard lending criteria apply.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={styles.inputWrapper}>
            <div style={styles.icon}>
              <User size={18} />
            </div>
            <input
              type="number"
              style={styles.input}
              value={formData.monthsInNetherlands}
              onChange={(e) => setFormData({ ...formData, monthsInNetherlands: e.target.value })}
              placeholder="0"
            />
          </div>
        </div>

        <button
          type="button"
          onClick={handleCalculate}
          style={styles.button}
        >
          Calculate Your Options
        </button>
      </div>

      {results && (
        <div style={styles.results}>
          <div style={styles.resultsGrid}>
            <div style={styles.resultBox}>
              <h3 style={styles.resultTitle}>Maximum Mortgage</h3>
              <p style={styles.resultValue}>{formatCurrency(results.maxMortgage)}</p>
            </div>

            <div style={styles.resultBox}>
              <h3 style={styles.resultTitle}>Monthly Payment</h3>
              <p style={styles.resultValue}>{formatCurrency(results.monthlyPayment)}</p>
            </div>

            <div style={styles.resultBox}>
              <h3 style={styles.resultTitle}>Additional Costs</h3>
              <p style={styles.resultValue}>
                {formatCurrency(results.additionalCosts * 0.9)} - {formatCurrency(results.additionalCosts * 1.1)}
              </p>
            </div>
          </div>

          <div style={styles.tableContainer}>
            <h3 style={{...styles.resultTitle, padding: '16px 24px', borderBottom: `1px solid ${colors.border.default}`}}>
              Additional Costs Breakdown
            </h3>
            <div style={{padding: '24px'}}>
              <div style={styles.costsGrid}>
                <div style={styles.resultBox}>
                  <div style={styles.resultTitle}>Transfer Tax</div>
                  <p style={styles.resultValue}>
                    {formatCurrency(results.transferTax * 0.95)} - {formatCurrency(results.transferTax * 1.05)}
                  </p>
                  <p style={{...styles.label, marginTop: '8px'}}>2% of property value - Required tax when buying property</p>
                </div>

                <div style={styles.resultBox}>
                  <div style={styles.resultTitle}>Notary Fees</div>
                  <p style={styles.resultValue}>€1,500 - €2,500</p>
                  <p style={{...styles.label, marginTop: '8px'}}>Legal documentation and registration</p>
                </div>

                <div style={styles.resultBox}>
                  <div style={styles.resultTitle}>Mortgage Advisor</div>
                  <p style={styles.resultValue}>€2,000 - €3,000</p>
                  <p style={{...styles.label, marginTop: '8px'}}>Professional financial advice</p>
                </div>

                <div style={styles.resultBox}>
                  <div style={styles.resultTitle}>Bank & Valuation Fees</div>
                  <p style={styles.resultValue}>€500 - €1,000</p>
                  <p style={{...styles.label, marginTop: '8px'}}>Required for mortgage approval</p>
                </div>
              </div>
            </div>
          </div>

          <MortgageBreakdown 
            amortizationSchedule={results.amortizationSchedule}
            mortgageStats={results.mortgageStats}
          />
        </div>
      )}
    </div>
  );
};

export default Calculator;