// src/components/Contact/styles.js
const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  }
};

export const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '32px'
  },

  section: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: `1px solid ${colors.border.default}`,
  },

  contentWrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'flex-start'
  },

  iconWrapper: {
    width: '48px',
    height: '48px',
    borderRadius: '12px',
    backgroundColor: colors.background.input,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    border: `1px solid ${colors.border.default}`
  },

  icon: {
    color: colors.primary
  },

  content: {
    flex: 1
  },

  title: {
    color: colors.text.primary,
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '20px',
    fontFamily: "'Inter', sans-serif"
  },

  alertBox: {
    backgroundColor: colors.background.input,
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '24px',
    border: `1px solid ${colors.border.default}`
  },

  alertTitle: {
    color: colors.text.primary,
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '12px',
    fontFamily: "'Inter', sans-serif"
  },

  alertDescription: {
    color: colors.text.secondary,
    fontSize: '15px',
    lineHeight: '1.6',
    fontFamily: "'Inter', sans-serif"
  },

  bulletList: {
    listStyle: 'none',
    padding: '0',
    margin: '12px 0',
    '& li': {
      color: colors.text.secondary,
      marginBottom: '8px',
      paddingLeft: '20px',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '8px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: colors.primary
      }
    }
  },

  emailLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: colors.primary,
    fontSize: '15px',
    fontWeight: '500',
    textDecoration: 'none',
    fontFamily: "'Inter', sans-serif",
    transition: 'color 0.2s ease',
    '&:hover': {
      color: colors.text.primary
    }
  },

  responseTime: {
    color: colors.text.muted,
    fontSize: '14px',
    marginTop: '12px',
    fontFamily: "'Inter', sans-serif"
  }
};