// src/components/Calculator/styles.js
export const colors = {
  primary: '#A48AFB',
  neon: {
    purple: '#A48AFB',
    glow: 'rgba(164, 138, 251, 0.15)'
  },
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  }
};

export const styles = {
  // Main Container
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '32px'
  },

  // Calculator Form
  calculatorForm: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: `1px solid ${colors.border.default}`,
    marginBottom: '32px'
  },

  formGroup: {
    marginBottom: '24px'
  },

  // Labels
  label: {
    display: 'block',
    marginBottom: '8px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    color: colors.text.primary,
    fontSize: '14px',
    letterSpacing: '0.01em'
  },

  // Input Styling
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  input: {
    width: '100%',
    height: '40px',
    paddingLeft: '40px',
    paddingRight: '16px',
    fontSize: '15px',
    fontFamily: "'Inter', sans-serif",
    color: colors.text.primary,
    backgroundColor: colors.background.input,
    border: `1px solid ${colors.border.default}`,
    borderRadius: '8px',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: colors.border.hover,
      backgroundColor: colors.background.darker
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.border.focus,
      boxShadow: `0 0 0 2px ${colors.primary}25`
    }
  },

  // Icons
  icon: {
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    color: colors.text.muted,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
    height: '18px',
    zIndex: 1
  },

  // Select Fields
  select: {
    width: '100%',
    height: '42px',
    backgroundColor: colors.background.input,
    padding: '0 16px 0 40px',
    appearance: 'none',
    fontSize: '15px',
    fontFamily: "'Inter', sans-serif",
    color: colors.text.primary,
    border: `1px solid ${colors.border.default}`,
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23A1A1AA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 14px center',
    backgroundSize: '16px',
    '&:hover': {
      borderColor: colors.border.hover,
      backgroundColor: colors.background.darker
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.border.focus,
      boxShadow: `0 0 0 2px ${colors.primary}25`
    }
  },

  selectWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  // Results Section
  results: {
    marginTop: '32px'
  },

  resultsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
    marginBottom: '32px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: '1fr',
    }
  },

  resultBox: {
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: colors.background.card,
    border: `1px solid ${colors.border.default}`,
    transition: 'all 0.2s ease',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: `0 8px 16px rgba(0, 0, 0, 0.2), 0 0 20px ${colors.neon.glow}`,
      border: `1px solid ${colors.neon.purple}`
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '2px',
      background: `linear-gradient(90deg, transparent, ${colors.neon.purple}, transparent)`,
      opacity: 0,
      transition: 'opacity 0.2s ease'
    },
    '&:hover::before': {
      opacity: 1
    }
  },

  // Typography for results
  resultTitle: {
    fontSize: '14px',
    color: colors.neon.purple,
    marginBottom: '8px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif",
    textShadow: `0 0 10px ${colors.neon.glow}`,
    letterSpacing: '0.5px'
  },

  resultValue: {
    fontSize: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '600',
    color: colors.text.primary,
    margin: 0
  },

  // Button styling
  button: {
    width: '100%',
    padding: '14px 24px',
    backgroundColor: colors.primary,
    color: colors.text.primary,
    border: 'none',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '&:hover': {
      opacity: 0.9,
      transform: 'translateY(-1px)'
    },
    '&:active': {
      transform: 'translateY(0)',
    }
  },

  // Table styles
  tableContainer: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    overflow: 'hidden',
    border: `1px solid ${colors.border.default}`,
    marginTop: '32px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '2px',
      background: `linear-gradient(90deg, transparent, ${colors.neon.purple}, transparent)`,
      opacity: 0.5
    }
  },

  tableHeader: {
    backgroundColor: colors.background.input,
    color: colors.text.secondary,
    padding: '16px',
    textAlign: 'left',
    fontWeight: '500',
    fontFamily: "'Inter', sans-serif",
    borderBottom: `1px solid ${colors.border.default}`
  },

  tableCell: {
    padding: '16px',
    borderBottom: `1px solid ${colors.border.default}`,
    color: colors.text.primary,
    fontFamily: "'Inter', sans-serif"
  },

  // Costs Grid
  costsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    }
  },

  // Tooltip styles
  infoIconWrapper: {
    position: 'relative',
    display: 'inline-block',
  },

  infoIcon: {
    cursor: 'pointer',
    color: colors.primary,
    transition: 'color 0.2s ease',
    '&:hover': {
      color: colors.text.primary
    }
  },

  tooltip: {
    position: 'absolute',
    right: '0',
    top: 'calc(100% + 8px)',
    width: '320px',
    padding: '16px',
    backgroundColor: colors.background.card,
    border: `1px solid ${colors.border.default}`,
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    zIndex: 50
  },

  tooltipHeader: {
    margin: '0 0 16px 0',
    color: colors.primary,
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  tooltipSection: {
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: 0
    }
  },

  tooltipTitle: {
    color: colors.text.primary,
    fontWeight: '600',
    fontSize: '14px',
    marginBottom: '4px',
    fontFamily: "'Inter', sans-serif"
  },

  tooltipText: {
    color: colors.text.secondary,
    fontSize: '13px',
    lineHeight: '1.5',
    margin: '4px 0 0 0',
    fontFamily: "'Inter', sans-serif"
  },

  tooltipList: {
    marginTop: '4px', 
    paddingLeft: '20px', 
    color: colors.text.secondary, 
    fontSize: '13px',
    fontFamily: "'Inter', sans-serif"
  },

  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px'
  },

  // Mortgage Breakdown Section
  breakdownContainer: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    overflow: 'hidden',
    border: `1px solid ${colors.border.default}`,
    marginTop: '32px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '2px',
      background: `linear-gradient(90deg, transparent, ${colors.neon.purple}, transparent)`,
      opacity: 0.5
    }
  },

  breakdownHeader: {
    padding: '16px 24px',
    borderBottom: `1px solid ${colors.border.default}`,
    backgroundColor: colors.background.input,
    position: 'relative'
  },

  breakdownTitle: {
    fontSize: '16px',
    color: colors.neon.purple,
    fontWeight: '600',
    margin: 0,
    fontFamily: "'Inter', sans-serif",
    textShadow: `0 0 10px ${colors.neon.glow}`,
    letterSpacing: '0.5px'
  },

  breakdownContent: {
    padding: '24px'
  },

  breakdownGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
    marginBottom: '24px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: '1fr',
    }
  },

  breakdownItem: {
    padding: '16px',
    backgroundColor: colors.background.input,
    borderRadius: '8px',
    border: `1px solid ${colors.border.default}`
  },

  breakdownLabel: {
    fontSize: '14px',
    color: colors.text.secondary,
    marginBottom: '8px',
    fontFamily: "'Inter', sans-serif"
  },

  breakdownValue: {
    fontSize: '18px',
    color: colors.text.primary,
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif"
  },

  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '24px'
  },

  thead: {
    backgroundColor: colors.background.input
  },

  th: {
    padding: '12px 16px',
    textAlign: 'left',
    color: colors.text.secondary,
    fontWeight: '500',
    fontSize: '14px',
    borderBottom: `1px solid ${colors.border.default}`,
    fontFamily: "'Inter', sans-serif"
  },

  td: {
    padding: '12px 16px',
    color: colors.text.primary,
    fontSize: '14px',
    borderBottom: `1px solid ${colors.border.default}`,
    fontFamily: "'Inter', sans-serif"
  }
};