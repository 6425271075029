// src/components/PaymentSuccess/styles.js
const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A',
    white: '#FFFFFF'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  },
  state: {
    success: '#22C55E',
    error: '#EF4444'
  }
};

export const styles = {
  container: {
    backgroundColor: colors.background.darker,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px'
  },

  card: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    padding: '32px',
    maxWidth: '480px',
    width: '100%',
    textAlign: 'center',
    border: `1px solid ${colors.border.default}`
  },

  title: {
    color: colors.text.primary,
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '24px'
  },

  emailIcon: {
    width: '48px',
    height: '48px',
    color: colors.primary,
    marginBottom: '24px'
  },

  emailTitle: {
    color: colors.text.primary,
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px'
  },

  emailMessage: {
    color: colors.text.secondary,
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '16px'
  },

  emailSubmessage: {
    color: colors.text.muted,
    fontSize: '14px'
  },

  errorTitle: {
    color: colors.state.error,
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px'
  },

  error: {
    color: colors.text.secondary,
    fontSize: '16px',
    marginBottom: '16px'
  },

  submessage: {
    color: colors.text.muted,
    fontSize: '14px'
  }
};