const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  }
};

export const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '1200px',
    padding: '0px 16px',
    marginBottom: '24px'
  },

  selectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    position: 'relative'
  },

  selectButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    backgroundColor: colors.background.input,
    border: `1px solid ${colors.border.default}`,
    borderRadius: '8px',
    width: '240px',
    cursor: 'pointer',
    fontSize: '15px',
    color: colors.text.primary,
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: colors.primary,
      backgroundColor: colors.background.darker
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.primary,
      boxShadow: `0 0 0 1px ${colors.primary}`
    }
  },

  selectedContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },

  flag: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center'
  },

  dropdownContainer: {
    position: 'absolute',
    top: 'calc(100% + 8px)',
    backgroundColor: colors.background.card,
    border: `1px solid ${colors.border.default}`,
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
    width: '240px',
    overflow: 'hidden',
    zIndex: 10
  },

  searchInput: {
    width: '100%',
    padding: '12px 16px',
    border: 'none',
    borderBottom: `1px solid ${colors.border.default}`,
    fontSize: '15px',
    outline: 'none',
    backgroundColor: colors.background.input,
    color: colors.text.primary,
    '&::placeholder': {
      color: colors.text.muted
    },
    '&:focus': {
      borderColor: colors.primary,
      borderBottom: `1px solid ${colors.primary}`
    }
  },

  countryList: {
    maxHeight: '240px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: colors.background.darker
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.border.default,
      borderRadius: '4px',
      '&:hover': {
        background: colors.border.hover
      }
    }
  },

  countryOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    cursor: 'pointer',
    fontSize: '15px',
    gap: '8px',
    width: '100%',
    color: colors.text.secondary,
    transition: 'all 0.15s ease',
    '&:hover': {
      backgroundColor: colors.background.input,
      color: colors.text.primary
    },
    '&[data-selected="true"]': {
      backgroundColor: `${colors.primary}20`,
      color: colors.primary
    }
  },

  noResults: {
    padding: '16px',
    textAlign: 'center',
    color: colors.text.muted,
    fontSize: '14px'
  }
}; 