import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { LoadingOverlay } from './LoadingStates';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <LoadingOverlay message="Loading..." />;
  }

  // Only redirect to home page if not on auth callback and no user
  if (!user && location.pathname !== '/auth/callback') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;