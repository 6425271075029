const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  }
};

export const styles = {
  disclaimer: {
    backgroundColor: colors.background.card,
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: `1px solid ${colors.border.default}`,
    marginTop: '32px',
    maxWidth: '1200px',
    margin: '32px auto 0'
  },

  disclaimerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: colors.primary,
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '16px',
    fontFamily: "'Inter', sans-serif",
    '& svg': {
      color: colors.primary
    }
  },

  disclaimerContent: {
    color: colors.text.secondary,
    fontSize: '14px',
    lineHeight: '1.6',
    fontFamily: "'Inter', sans-serif",
    '& p': {
      marginBottom: '16px'
    }
  },

  disclaimerList: {
    listStyle: 'disc',
    paddingLeft: '24px',
    margin: '16px 0'
  },

  disclaimerItem: {
    marginBottom: '12px',
    color: colors.text.secondary,
    '&:last-child': {
      marginBottom: 0
    }
  },

  disclaimerEmphasis: {
    color: colors.text.primary,
    fontWeight: '600',
    display: 'inline',
    marginRight: '4px'
  }
};