// frontend/src/services/api.js
export const API_BASE_URL = process.env.NODE_ENV === 'production' 
    ? 'https://api.liferelocate.com'
    : 'http://localhost:3001';

const apiRequest = async (endpoint, options = {}) => {
    try {
        const url = `${API_BASE_URL}${endpoint}`;
        
        const response = await fetch(url, {
            ...options,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });

        // Add better error handling
        if (!response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const error = await response.json();
                throw new Error(error.error || 'API request failed');
            } else {
                const text = await response.text();
                console.error('Non-JSON response:', text);
                throw new Error(`Server returned ${response.status}: ${text}`);
            }
        }

        return response.json();
    } catch (error) {
        console.error('API request failed:', error);
        throw error;
    }
};

// API methods

export const createCheckoutSession = (email) => 
    apiRequest('/payment/create-checkout', {
        method: 'POST',
        body: JSON.stringify({ email })
    });

export const verifyPayment = (sessionId) => 
    apiRequest('/payment/verify-payment', {
        method: 'POST',
        body: JSON.stringify({ sessionId })
    });

export const requestMagicLink = (email) =>
    apiRequest('/magic/request-magic-link', {
        method: 'POST',
        body: JSON.stringify({ email })
    });

export const verifyMagicLink = (token) =>
    apiRequest('/magic/verify-magic-link', {
        method: 'POST',
        body: JSON.stringify({ token })
    });

export const checkUserAccess = (email) => 
    apiRequest('/auth/check-access', {
        method: 'POST',
        body: JSON.stringify({ email })
    });


