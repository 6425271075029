const colors = {
  primary: '#A48AFB',
  background: {
    dark: '#1A1A1A',
    darker: '#141414',
    card: '#242424',
    input: '#2A2A2A',
    white: '#FFFFFF'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A1A1AA',
    muted: '#71717A'
  },
  border: {
    default: '#333333',
    focus: '#A48AFB',
    hover: '#404040'
  },
  state: {
    success: '#22C55E',
    error: '#EF4444'
  }
};

export const styles = {
    container: {
        backgroundColor: colors.background.darker,
        minHeight: '100vh',
        padding: '0'
    },

    header: {
        width: '100%',
        padding: '24px 0',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: 'transparent'
    },

    headerContent: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },

    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },

    logoText: {
        fontSize: '32px',
        fontWeight: 600,
        color: colors.text.primary,
        margin: 0,
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        '@media (max-width: 768px)': {
            gap: '0'
        }
    },

    // Hero Section (Section 1)
    heroSection: {
        minHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        padding: '120px 24px 60px',
        position: 'relative',
        background: `linear-gradient(180deg, ${colors.background.darker} 0%, ${colors.background.dark} 100%)`,
        '@media (max-width: 768px)': {
            padding: '100px 24px 40px',
            minHeight: 'auto'
        }
    },

    heroContent: {
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '100px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            gap: '48px'
        }
    },

    contentLeft: {
        flex: '1.5',
        maxWidth: '700px',
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        '@media (max-width: 768px)': {
            maxWidth: '100%',
            gap: '32px'
        }
    },

    headingGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },

    mainHeading: {
        fontSize: '64px',
        fontWeight: 700,
        color: colors.text.primary,
        lineHeight: '1.1',
        marginTop: 0,
        marginBottom: 0,
        fontFamily: 'Inter, system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '44px'
        }
    },

    mainSubheading: {
        fontSize: '22px',
        color: colors.text.secondary,
        lineHeight: '1.6',
        margin: 0,
        fontFamily: 'system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '18px'
        }
    },

    // Calculator and Guide Sections
    calculatorSection: {
        padding: '100px 24px',
        background: colors.background.darker,
        borderBottom: `1px solid ${colors.border.default}`,
        '@media (max-width: 768px)': {
            padding: '60px 24px'
        }
    },

    guideSection: {
        padding: '100px 24px',
        background: colors.background.darker,
        borderBottom: `1px solid ${colors.border.default}`,
        '@media (max-width: 768px)': {
            padding: '60px 24px'
        }
    },

    sectionContent: {
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '60px',
        '@media (max-width: 768px)': {
            gap: '40px'
        }
    },

    sectionTitle: {
        fontSize: '48px',
        fontWeight: 700,
        color: colors.text.primary,
        margin: '0',
        textAlign: 'center',
        maxWidth: '800px',
        lineHeight: '1.2',
        fontFamily: 'Inter, system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '36px'
        }
    },

    featureList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto'
    },

    featureItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        color: colors.text.primary,
        padding: '4px 0'
    },

    featureIcon: {
        width: '28px',
        height: '28px',
        color: colors.primary,
        flexShrink: 0
    },

    featureText: {
        fontSize: '18px',
        lineHeight: '1.5',
        color: colors.text.secondary,
        fontFamily: 'system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '16px'
        }
    },

    // Countries Section
    countriesSection: {
        padding: '100px 24px',
        background: colors.background.darker,
        '@media (max-width: 768px)': {
            padding: '60px 24px'
        }
    },

    countriesTitle: {
        fontSize: '48px',
        fontWeight: 700,
        color: colors.text.primary,
        margin: '0 0 24px 0',
        textAlign: 'center',
        fontFamily: 'Inter, system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '36px'
        }
    },

    countriesSubtitle: {
        fontSize: '20px',
        color: colors.text.secondary,
        margin: '0 0 60px 0',
        textAlign: 'center',
        maxWidth: '600px',
        margin: '0 auto 60px',
        fontFamily: 'system-ui, sans-serif',
        '@media (max-width: 768px)': {
            fontSize: '18px',
            margin: '0 auto 40px'
        }
    },

    // Preview Images
    calculatorPreview: {
        width: '100%',
        maxWidth: '1100px',
        margin: '0 auto',
        '@media (max-width: 1024px)': {
            maxWidth: '100%'
        }
    },

    guidePreview: {
        width: '100%',
        maxWidth: '1100px',
        margin: '0 auto',
        '@media (max-width: 1024px)': {
            maxWidth: '100%'
        }
    },

    previewImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '20px',
        border: `1px solid ${colors.border.default}`,
        backgroundColor: colors.background.card,
        boxShadow: '0 12px 40px rgba(0, 0, 0, 0.4)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0 20px 60px rgba(0, 0, 0, 0.5)'
        }
    },

    previewPlaceholder: {
        width: '100%',
        aspectRatio: '4/3',
        backgroundColor: colors.background.card,
        borderRadius: '12px',
        border: `1px solid ${colors.border.default}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.text.secondary,
        fontSize: '16px',
        fontFamily: 'system-ui, sans-serif'
    },

    // Existing styles...
    featureList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        // maxWidth: '800px',
        // margin: '0 auto',
        width: '100%'
    },

    featureItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        color: colors.text.primary
    },

    featureIcon: {
        width: '24px',
        height: '24px',
        color: colors.primary,
        flexShrink: 0
    },

    featureText: {
        fontSize: '16px',
        lineHeight: '1.5',
        color: colors.text.secondary,
        fontFamily: 'system-ui, sans-serif'
    },

    contentRight: {
        width: '350px',
        flexShrink: 0,
        '@media (max-width: 768px)': {
            width: '100%',
            maxWidth: '400px'
        }
    },

    emailForm: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    emailCard: {
        backgroundColor: colors.background.card,
        borderRadius: '12px',
        padding: '24px',
        width: '100%',
        position: 'relative',
        border: `1px solid ${colors.border.default}`,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
    },

    newFeatureBadge: {
        position: 'absolute',
        top: '-8px',
        right: '24px',
        backgroundColor: colors.primary,
        color: colors.text.primary,
        padding: '4px 12px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    emailInput: {
        padding: '12px 16px',
        fontSize: '16px',
        backgroundColor: colors.background.input,
        border: `1px solid ${colors.border.default}`,
        borderRadius: '8px',
        marginBottom: '16px',
        outline: 'none',
        color: colors.text.primary,
        fontFamily: 'system-ui, sans-serif',
        '&:focus': {
            borderColor: colors.primary,
            boxShadow: `0 0 0 1px ${colors.primary}`
        },
        '&::placeholder': {
            color: colors.text.muted
        }
    },

    submitButton: {
        width: '100%',
        padding: '12px 16px',
        backgroundColor: colors.primary,
        color: colors.text.primary,
        border: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 600,
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        fontFamily: 'Inter, system-ui, sans-serif',
        '&:hover': {
            opacity: 0.9
        }
    },

    submitButtonDisabled: {
        opacity: 0.7,
        cursor: 'not-allowed',
        '&:hover': {
            opacity: 0.7
        }
    },

    pricingText: {
        fontSize: '14px',
        color: colors.text.secondary,
        textAlign: 'center',
        marginTop: '16px'
    },

    earlyBirdNote: {
        fontSize: '13px',
        color: colors.text.muted,
        textAlign: 'center',
        marginTop: '8px',
        fontStyle: 'italic',
        padding: '0 16px',
        lineHeight: '1.4'
    },

    magicLinkSuccess: {
        textAlign: 'center'
    },

    magicLinkSuccessIcon: {
        width: '48px',
        height: '48px',
        color: colors.primary,
        marginBottom: '24px'
    },

    magicLinkSuccessTitle: {
        fontSize: '24px',
        fontWeight: 600,
        color: colors.primary,
        marginBottom: '16px',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    magicLinkSuccessText: {
        color: colors.text.secondary,
        lineHeight: '1.5',
        marginBottom: '24px',
        fontSize: '16px',
        fontFamily: 'system-ui, sans-serif'
    },

    errorText: {
        color: colors.state.error,
        fontSize: '14px',
        marginTop: '8px',
        fontFamily: 'system-ui, sans-serif'
    },

    // Countries Section (Section 4)
    countriesSection: {
        padding: '40px 24px 80px',
        background: colors.background.darker,
        '@media (max-width: 768px)': {
            padding: '40px 24px 60px'
        }
    },

    countriesTitle: {
        fontSize: '36px',
        fontWeight: 700,
        color: colors.text.primary,
        margin: '0 0 16px 0',
        textAlign: 'center',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    countriesSubtitle: {
        fontSize: '18px',
        color: colors.text.secondary,
        margin: '0 0 48px 0',
        textAlign: 'center',
        fontFamily: 'system-ui, sans-serif'
    },

    countriesGrid: {
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '24px',
        '@media (max-width: 1024px)': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))'
        }
    },

    countryCard: {
        backgroundColor: colors.background.darker,
        borderRadius: '12px',
        padding: '24px',
        border: `1px solid ${colors.primary}`,
        transition: 'all 0.2s ease',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(164, 138, 251, 0.15)'
        }
    },

    countryFlag: {
        fontSize: '32px',
        display: 'block',
        marginBottom: '16px'
    },

    countryName: {
        fontSize: '20px',
        fontWeight: 600,
        color: colors.text.primary,
        margin: '0 0 16px 0',
        fontFamily: 'Inter, system-ui, sans-serif',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },

    comingSoonCard: {
        backgroundColor: colors.background.darker,
        borderRadius: '12px',
        padding: '24px',
        border: `1px solid ${colors.border.default}`,
        opacity: 0.7,
        transition: 'all 0.2s ease',
        position: 'relative',
        '&:hover': {
            opacity: 0.8,
            transform: 'translateY(-2px)'
        }
    },

    comingSoonFlag: {
        fontSize: '32px',
        display: 'block',
        marginBottom: '16px',
        filter: 'grayscale(1)'
    },

    comingSoonTitle: {
        fontSize: '20px',
        fontWeight: 600,
        color: colors.text.primary,
        margin: '0 0 8px 0',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    comingSoonText: {
        fontSize: '14px',
        color: colors.text.secondary,
        margin: 0,
        fontFamily: 'system-ui, sans-serif',
        fontStyle: 'italic'
    },

    scrollIndicator: {
        position: 'absolute',
        bottom: '80px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        opacity: 0.9,
        animation: 'fadeInOut 2s ease-in-out infinite',
        cursor: 'pointer',
        transition: 'opacity 0.2s ease',
        zIndex: 10,
        padding: '12px',
        '&:hover': {
            opacity: 1
        }
    },

    scrollText: {
        color: colors.text.primary,
        fontSize: '16px',
        fontFamily: 'Inter, system-ui, sans-serif',
        fontWeight: 500
    },

    scrollIcon: {
        width: '32px',
        height: '32px',
        color: colors.primary,
        animation: 'bounce 2s infinite'
    },

    '@keyframes fadeInOut': {
        '0%, 100%': { opacity: 0.8 },
        '50%': { opacity: 0.4 }
    },

    '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
            transform: 'translateY(0)'
        },
        '40%': {
            transform: 'translateY(-8px)'
        },
        '60%': {
            transform: 'translateY(-4px)'
        }
    }
};