// src/components/Calculator/EmploymentTypeTooltip.jsx
import React, { useState } from 'react';
import { User, Info } from 'lucide-react';
import { styles } from './styles';

const EmploymentTypeTooltip = ({ value, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div style={styles.formGroup}>
      <div style={styles.labelWrapper}>
        <label style={styles.label}>Employment Type</label>
        <div 
          style={styles.infoIconWrapper}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Info size={18} style={styles.infoIcon} />
          {showTooltip && (
            <div style={{
              ...styles.tableContainer,
              position: 'absolute',
              right: 0,
              top: '100%',
              marginTop: '8px',
              width: '300px',
              zIndex: 10,
            }}>
              <div style={{padding: '16px'}}>
                <h4 style={styles.resultTitle}>Employment Types Explained</h4>
                
                <div style={{marginTop: '16px'}}>
                  <div style={styles.resultTitle}>Permanent Contract</div>
                  <p style={{...styles.label, marginTop: '4px'}}>
                    Full lending capacity, most favorable terms
                  </p>
                </div>
                
                <div style={{marginTop: '16px'}}>
                  <div style={styles.resultTitle}>Temporary Contract</div>
                  <p style={{...styles.label, marginTop: '4px'}}>
                    Up to 90% lending capacity with intent declaration
                  </p>
                </div>
                
                <div style={{marginTop: '16px'}}>
                  <div style={styles.resultTitle}>Self-Employed</div>
                  <p style={{...styles.label, marginTop: '4px'}}>
                    Requires 3+ years of business history
                  </p>
                </div>
                
                <div style={{marginTop: '16px'}}>
                  <div style={styles.resultTitle}>Freelancer/Contractor</div>
                  <p style={{...styles.label, marginTop: '4px'}}>
                    Average income of last 3 years considered
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div style={styles.selectWrapper}>
        <div style={styles.icon}>
          <User size={18} />
        </div>
        <select 
          value={value} 
          onChange={onChange}
          style={styles.select}
        >
          <option value="permanent">Permanent Contract</option>
          <option value="temporary">Temporary Contract</option>
          <option value="self-employed">Self-Employed</option>
          <option value="freelancer">Freelancer/Contractor</option>
        </select>
      </div>
    </div>
  );
};

export default EmploymentTypeTooltip;