// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { API_BASE_URL } from '../services/api';

// Create the context
const AuthContext = createContext({});

// Create the hook first
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

// Create the provider component
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateUser = async (newUser) => {
    setLoading(true);
    try {
      setUser(newUser);
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/session`, {
            credentials: 'include'
        });
        const data = await response.json();
        
        
        if (data.user) {
            setUser(data.user);
            return true;
        } else {
            setUser(null);
            return false;
        }
    } catch (error) {
        console.error('Detailed session check error:', error);
        setUser(null);
        return false;
    } finally {
        setLoading(false);
    }
  };

  const value = {
    user,
    setUser: updateUser,
    loading,
    checkSession
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}