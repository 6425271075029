import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { styles } from './CountrySelector/styles';

// We'll expand this as we add more countries
const countries = [
  {
    code: 'NL',
    name: 'Netherlands',
    flag: '🇳🇱',
    config: {
      transferTaxRate: 0.02,
      mortgageYears: [10, 15, 20, 25, 30],
      baseMultiplier: 4.5,
      highIncomeThreshold: 80000,
      highIncomeMultiplier: 5.0,
      employmentTypes: [
        { value: 'permanent', label: 'Permanent Contract', reduction: 0 },
        { value: 'temporary', label: 'Temporary Contract', reduction: 0.1 },
        { value: 'self-employed', label: 'Self Employed', reduction: 0.2 }
      ],
      residencyRules: {
        lessThan3Months: 0.7,
        lessThan6Months: 0.8,
        lessThan12Months: 0.9,
        established: 1.0
      },
      additionalCosts: {
        notaryFees: 2000,
        advisorFees: 2500,
        bankFees: 750
      }
    }
  }
];

const CountrySelector = ({ selectedCountry, setSelectedCountry }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCountries = countries.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={styles.container}>
      <div style={styles.selectorWrapper}>
        <div style={{ position: 'relative' }} ref={dropdownRef}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            style={styles.selectButton}
          >
            <span style={styles.selectedContent}>
              <span style={styles.flag}>{selectedCountry?.flag}</span>
              {selectedCountry?.name || "Select country..."}
            </span>
            <ChevronDown 
              size={20} 
              color="#A1A1AA"
              style={{
                transform: isOpen ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s ease'
              }}
            />
          </button>

          {isOpen && (
            <div style={styles.dropdownContainer}>
              <input
                type="text"
                placeholder="Search country..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={styles.searchInput}
                autoFocus
              />
              <div style={styles.countryList}>
                {filteredCountries.map((country) => (
                  <div
                    key={country.code}
                    style={styles.countryOption}
                    onClick={() => {
                      setSelectedCountry(country);
                      setIsOpen(false);
                      setSearchTerm('');
                    }}
                  >
                    <span style={styles.flag}>{country.flag}</span>
                    {country.name}
                  </div>
                ))}
                {filteredCountries.length === 0 && (
                  <div style={styles.noResults}>No countries found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountrySelector;