// src/components/Calculator/InputField.jsx
import React from 'react';
import { styles } from './styles';

const InputField = ({ label, icon: Icon, ...props }) => {
  return (
    <div style={styles.formGroup}>
      <label style={styles.label}>{label}</label>
      <div style={styles.inputWrapper}>
        <div style={styles.icon}>
          <Icon size={18} />
        </div>
        <input
          {...props}
          style={styles.input}
        />
      </div>
    </div>
  );
};

export default InputField;