// src/components/Calculator/MortgageBreakdown.jsx
import React from 'react';
import { Info } from 'lucide-react';
import { styles, colors } from './styles';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

const MortgageStats = ({ mortgageStats }) => (
  <div style={styles.tableContainer}>
    <div style={styles.breakdownHeader}>
      <h3 style={styles.breakdownTitle}>Mortgage Payment Breakdown</h3>
    </div>
    <div style={styles.breakdownContent}>
      <div style={styles.breakdownGrid}>
        <div style={styles.breakdownItem}>
          <div style={styles.breakdownLabel}>Principal Amount</div>
          <div style={styles.breakdownValue}>
            {formatCurrency(mortgageStats.principalAmount)}
          </div>
        </div>

        <div style={styles.breakdownItem}>
          <div style={styles.breakdownLabel}>Interest Rate</div>
          <div style={styles.breakdownValue}>
            {mortgageStats.interestRate.toFixed(2)}%
          </div>
        </div>

        <div style={styles.breakdownItem}>
          <div style={styles.breakdownLabel}>Loan Term</div>
          <div style={styles.breakdownValue}>
            {mortgageStats.mortgageYears} years
          </div>
        </div>

        <div style={styles.breakdownItem}>
          <div style={styles.breakdownLabel}>Total Interest</div>
          <div style={styles.breakdownValue}>
            {formatCurrency(mortgageStats.totalInterest)}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const MortgageBreakdown = ({ amortizationSchedule, mortgageStats }) => {
  return (
    <div style={styles.results}>
      {/* Summary Stats */}
      <MortgageStats mortgageStats={mortgageStats} />

      {/* Table Container */}
      <div style={styles.tableContainer}>
        <div style={styles.breakdownHeader}>
          <h3 style={styles.breakdownTitle}>First Year Monthly Payments</h3>
        </div>

        <div style={styles.breakdownContent}>
          <table style={styles.table}>
            <thead style={styles.thead}>
              <tr>
                <th style={styles.th}>Month</th>
                <th style={styles.th}>Principal</th>
                <th style={styles.th}>Interest</th>
                <th style={styles.th}>Total Payment</th>
                <th style={styles.th}>Remaining Balance</th>
              </tr>
            </thead>
            <tbody>
              {amortizationSchedule.map((payment) => (
                <tr key={payment.month}>
                  <td style={styles.td}>{payment.month}</td>
                  <td style={styles.td}>{formatCurrency(payment.principalPayment)}</td>
                  <td style={styles.td}>{formatCurrency(payment.interestPayment)}</td>
                  <td style={styles.td}>{formatCurrency(payment.totalPayment)}</td>
                  <td style={styles.td}>{formatCurrency(payment.remainingBalance)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ 
            marginTop: '16px', 
            display: 'flex', 
            alignItems: 'center', 
            gap: '8px',
            color: colors.text.secondary,
            fontSize: '14px',
            fontFamily: "'Inter', sans-serif"
          }}>
            <Info size={16} style={{ color: colors.text.secondary }} />
            <p>
              Assumes fixed interest rate, monthly compounding, and no extra repayments
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageBreakdown;