import React, { useState, useEffect } from 'react';
import { CheckCircle, Mail, Home, Calculator, BookOpen, Briefcase, Building2, Euro, FileCheck, ClipboardCheck, Clock, Building, Lightbulb, ChevronDown } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { styles } from './styles';
import { createCheckoutSession, checkUserAccess } from '../../services/api';
import { LoadingOverlay } from '../LoadingStates';
import { API_BASE_URL } from '../../services/api';
import calculatorPreview from '../../assets/calculator-preview.png';
import guidePreview from '../../assets/guide-preview.png';
import AnalyticsTracker from '../AnalyticsTracker';

const Landing = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    setErrorMessage('');
    
    try {
      const { hasAccess, error: accessError } = await checkUserAccess(email);
      
      if (accessError) throw new Error(accessError);

      if (hasAccess) {
        const response = await fetch(`${API_BASE_URL}/auth/request-magic-link`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email })
        });

        const data = await response.json();
        
        if (!response.ok) {
          if (response.status === 429) {
            const waitTime = data.waitSeconds || 60;
            setErrorMessage(`Please wait ${waitTime} seconds before requesting another magic link`);
            setCountdown(waitTime);
            
            const timer = setInterval(() => {
              setCountdown((prev) => {
                if (prev <= 1) {
                  clearInterval(timer);
                  setErrorMessage('');
                  setStatus('idle');
                  return 0;
                }
                return prev - 1;
              });
            }, 1000);
            
            setStatus('error');
            return;
          }
          throw new Error(data.error || 'Failed to send magic link');
        }

        setStatus('sent');
      } else {
        const { url, error: stripeError } = await createCheckoutSession(email);
        if (stripeError) throw new Error(stripeError);
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
      setErrorMessage(error.message || 'Something went wrong. Please try again.');
    }
  };

  const scrollToContent = () => {
    const calculatorSection = document.querySelector('#calculator-heading').closest('section');
    if (calculatorSection) {
      const headerOffset = 80;
      const elementPosition = calculatorSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <AnalyticsTracker />
      <Helmet>
        <title>Life Relocate - European Mortgage Calculator for Expats</title>
        <meta name="description" content="Calculate accurate mortgage possibilities in Europe as an expat. Get personalized estimates based on local bank rules, employment type, and foreigner status." />
        <meta name="keywords" content="expat mortgage, european mortgage calculator, netherlands mortgage, expat housing, mortgage guide, relocation guide" />
        
        {/* Open Graph / Social Media */}
        <meta property="og:title" content="Life Relocate - European Mortgage Calculator for Expats" />
        <meta property="og:description" content="Calculate accurate mortgage possibilities in Europe as an expat. Get personalized estimates based on local bank rules, employment type, and foreigner status." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={calculatorPreview} />
        
        {/* Additional SEO tags */}
        <link rel="canonical" href="https://liferelocate.com" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div style={styles.container}>
        <header style={styles.header} role="banner">
          <div style={styles.headerContent}>
            <div style={styles.logoContainer}>
              <Home size={32} color="white" aria-hidden="true" />
              <h1 style={styles.logoText}>Life Relocate</h1>
            </div>
          </div>
        </header>

        <main style={styles.mainContent} role="main">
          <section style={styles.heroSection} aria-labelledby="hero-heading" aria-label="Get accurate mortgage estimates for Europe">
            <div style={styles.heroContent}>
              <div style={styles.contentLeft}>
                <div style={styles.headingGroup}>
                  <h2 id="hero-heading" style={styles.mainHeading}>
                    Stop guessing your mortgage possibilities in Europe
                  </h2>
                  <p style={styles.mainSubheading}>
                    Generic calculators don't work when relocating - they ignore local rules and your foreigner status. 
                    Life Relocate helps give you accurate mortgage estimates and a detailed guide based on your expat status and employment type.
                  </p>
                </div>

                <div style={styles.featureList} role="list">
                  <div style={styles.featureItem} role="listitem">
                    <CheckCircle style={styles.featureIcon} aria-hidden="true" />
                    <span style={styles.featureText}>
                      Calculate mortgage amounts based on local lending rules and your income
                    </span>
                  </div>
                  <div style={styles.featureItem} role="listitem">
                    <CheckCircle style={styles.featureIcon} aria-hidden="true" />
                    <span style={styles.featureText}>
                      Detailed guides explaining mortgage requirements
                    </span>
                  </div>
                  <div style={styles.featureItem} role="listitem">
                    <CheckCircle style={styles.featureIcon} aria-hidden="true" />
                    <span style={styles.featureText}>
                      Early access pricing - be the first to try new countries as we expand
                    </span>
                  </div>
                </div>
              </div>

              <div style={styles.contentRight}>
                <div style={styles.emailCard}>
                  <div style={styles.newFeatureBadge} role="note">Early Bird Access</div>
                  {status === 'sent' ? (
                    <div style={styles.magicLinkSuccess} role="alert">
                      <Mail style={styles.magicLinkSuccessIcon} aria-hidden="true" />
                      <h3 style={styles.magicLinkSuccessTitle}>Check your email</h3>
                      <p style={styles.magicLinkSuccessText}>
                        We've sent a magic link to {email}.<br />
                        Click the link in your email to access the calculator.
                      </p>
                    </div>
                  ) : (
                    <form style={styles.emailForm} onSubmit={handleSubmit} aria-label="Early access signup form">
                      <input
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.emailInput}
                        placeholder="Type your email..."
                        disabled={status === 'loading'}
                        aria-label="Email address"
                      />
                      <button
                        type="submit"
                        disabled={status === 'loading' || !email || countdown > 0}
                        style={{
                          ...styles.submitButton,
                          ...(status === 'loading' || !email || countdown > 0 ? styles.submitButtonDisabled : {})
                        }}
                        aria-busy={status === 'loading'}
                      >
                        {countdown > 0 
                          ? `Wait ${countdown}s` 
                          : (status === 'loading' 
                            ? 'Processing...' 
                            : 'Get mortgage calculator and guide →')}
                      </button>
                      {status === 'error' && (
                        <p style={styles.errorText} role="alert">{errorMessage}</p>
                      )}
                      <div style={styles.pricingText}>
                        Early bird offer: Get lifetime access for just £10
                      </div>
                      <p style={styles.earlyBirdNote}>
                        Join now to lock in lifetime access to all future countries
                      </p>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div style={styles.scrollIndicator} onClick={scrollToContent} role="button" tabIndex={0}>
              <span style={styles.scrollText}>Scroll to learn more</span>
              <ChevronDown style={styles.scrollIcon} aria-hidden="true" />
            </div>
          </section>

          <section style={styles.calculatorSection} aria-labelledby="calculator-heading">
            <div style={styles.sectionContent}>
              <h2 id="calculator-heading" style={styles.sectionTitle}>
                Accurate Mortgage Calculations for Expats
              </h2>
              
              <div style={styles.calculatorPreview}>
                <img 
                  src={calculatorPreview}
                  alt="Interactive mortgage calculator interface showing income, savings, and mortgage calculation fields for expats"
                  style={styles.previewImage}
                  loading="lazy"
                />
              </div>

              <div style={styles.featureList} role="list">
                <div style={styles.featureItem} role="listitem">
                  <Briefcase style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Employment contract types (permanent, fixed-term, contractor)
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <Building2 style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Local bank lending rules for foreigners
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <Euro style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Income recognition based on your employment status
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <FileCheck style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Required documentation and eligibility criteria
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section style={styles.guideSection} aria-labelledby="guide-heading">
            <div style={styles.sectionContent}>
              <h2 id="guide-heading" style={styles.sectionTitle}>
                Comprehensive Relocation Guides
              </h2>

              <div style={styles.guidePreview}>
                <img 
                  src={guidePreview}
                  alt="Interactive guide showing step-by-step mortgage application process and requirements for expats"
                  style={styles.previewImage}
                  loading="lazy"
                />
              </div>

              <div style={styles.featureList} role="list">
                <div style={styles.featureItem} role="listitem">
                  <ClipboardCheck style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Required documentation for mortgage applications
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <Clock style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Timeline expectations and process steps
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <Building style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Bank-specific requirements and preferences
                  </span>
                </div>
                <div style={styles.featureItem} role="listitem">
                  <Lightbulb style={styles.featureIcon} aria-hidden="true" />
                  <span style={styles.featureText}>
                    Tips for improving your application chances
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section style={styles.countriesSection} aria-labelledby="countries-heading">
            <h2 id="countries-heading" style={styles.countriesTitle}>Coverage Roadmap</h2>
            <p style={styles.countriesSubtitle}>
              Starting with the Netherlands, expanding to more European countries soon:
            </p>
            <div style={styles.countriesGrid} role="list">
              <div style={styles.countryCard} role="listitem">
                <span style={styles.countryFlag} role="img" aria-label="Netherlands flag">🇳🇱</span>
                <h3 style={styles.countryName}>
                  Netherlands
                  <span style={{ ...styles.newFeatureBadge, position: 'static', fontSize: '12px' }}>Active</span>
                </h3>
              </div>
              <div style={styles.comingSoonCard} role="listitem">
                <span style={styles.comingSoonFlag} role="img" aria-label="Germany flag">🇩🇪</span>
                <h3 style={styles.comingSoonTitle}>Germany</h3>
                <p style={styles.comingSoonText}>Coming Q1 2025 - Early access price locked</p>
              </div>
              <div style={styles.comingSoonCard} role="listitem">
                <span style={styles.comingSoonFlag} role="img" aria-label="France flag">🇫🇷</span>
                <h3 style={styles.comingSoonTitle}>France</h3>
                <p style={styles.comingSoonText}>Coming Q1 2025 - Early access price locked</p>
              </div>
            </div>
          </section>
        </main>

        {status === 'loading' && (
          <LoadingOverlay message="Processing your request..." />
        )}
      </div>
    </>
  );
};

export default Landing;